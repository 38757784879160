<my-content placeholder="main">
    <div class="main-content">
        <div class="row" *ngIf="!visualizar">
            <div class="col-md-12" *blockUI="'placa'">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="form" (ngSubmit)="search()">
                            <h5 class="mb-0">Consultar Placas</h5>
                            <hr class="mt-2" />

                            <div class="form-group">
                                <div class="row d-flex">
                                    <div class="col-md-3">
                                        <label>Placa do Veículo</label>
                                        <input type="text" class="form-control form-control-sm"
                                            placeholder="Informe a placa do veículo" formControlName="placa"
                                            maxlength="7" />
                                    </div>
                                    <div class="d-flex flex-column col">
                                        <label>Data Inicial</label>
                                        <app-date-time-picker formControlName="dataInicio"
                                            inputDatetimeFormat="dd/MM/yyyy" noSelectTime></app-date-time-picker>
                                    </div>
                                    <div class="d-flex flex-column col">
                                        <label>Data Final</label>
                                        <app-date-time-picker formControlName="dataFim" inputDatetimeFormat="dd/MM/yyyy"
                                            noSelectTime></app-date-time-picker>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div class="d-flex flex-row btn-pesquisar">
                                <div class="input-group mb-3 justify-content-start">
                                    <button type="submit" class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                        style="gap:0.5rem">Pesquisar<i class="fa-solid fa-magnifying-glass"></i>
                                    </button>
                                    <button type="reset" class="btn btn-default btn-sm my-0 d-flex align-items-center"
                                        style="gap:0.5rem" (click)="limpar()">Limpar<i class="fa-solid fa-times"></i>
                                    </button>
                                </div>
                                <div class="input-group mb-3 justify-content-end btn-excel">
                                    <button type="button" class="btn btn-primary btn-sm my-0 d-flex align-items-center"
                                        style="gap: 0.5rem" (click)="exportExcel()">
                                        Excel <i class="fas fa-file-csv"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="table-responsive px-3" *ngIf="buscaFoiRealizada">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="tipoPlacaNome">Tipo</th>
                                    <th scope="col" sortable="numero">Número</th>
                                    <th scope="col" sortable="unidadeFiscalNome">Unidade Fiscal</th>
                                    <th scope="col" sortable="dataHora">Data e Hora</th>
                                    <th scope="col" class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!consultaRetornouResultado">
                                    <td colspan="5">Nenhum resultado encontrado!</td>
                                </tr>
                                <tr *ngFor="let data of placas$ | async">
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.tipoPlacaNome"></ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.numero"></ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight [result]="data.unidadeFiscalNome"></ngb-highlight>
                                    </td>
                                    <td class="py-1">
                                        <ngb-highlight
                                            [result]="data.dataHora | date: 'dd/MM/yyyy hh:mm'"></ngb-highlight>
                                    </td>
                                    <td class="py-1" style="width: 30%;">
                                        <div class="d-flex justify-content-center" style="gap: 1rem;">
                                            <button ngbTooltip="Visualizar"
                                                class="btn btn-sm default btn-outline d-flex justify-content-center align-items-center"
                                                (click)="onView(data)" style="gap:0.5rem" *ngIf="PodeVisualizar">
                                                <i class="fa-solid fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10"></ngb-pagination>
                            <select class="form-select form-control-sm" style="width: auto" name="pageSize"
                                [(ngModel)]="service.pageSize">
                                <option [ngValue]="10">10 Itens</option>
                                <option [ngValue]="15">15 Itens</option>
                                <option [ngValue]="20">20 Itens</option>
                            </select>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="visualizar">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h3>{{placa.tipoPlacaNome}}</h3>
                        <div class="form-horizontal mx-3">
                            <div class="form-body" *ngIf="ehMDFE">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Número</label>
                                            <p>{{ placa.numero }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">CPF Motorista</label>
                                            <p>{{ placa.cpfMotorista }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Chave Acesso</label>
                                            <p>{{ placa.chaveDeAcesso }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Data/Hora Autorizacao</label>
                                            <p>{{ placa.dataHora | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">UF Carregamento</label>
                                            <p>{{ placa.ufCarregamento }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">UF Descarregamento</label>
                                            <p>{{ placa.ufDescarregamento }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">UFs Percurso</label>
                                            <ul>
                                                <li *ngFor="let uf of placa.ufsPercurso">{{ uf }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body" *ngIf="ehRPE">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Número</label>
                                            <p>{{ placa.numero }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Unidade Fiscal</label>
                                            <p>{{ placa.unidadeFiscalNome }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Tipo Movimento</label>
                                            <p>{{ placa.tipoMovimento }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Data/Hora Geração</label>
                                            <p>{{ placa.dataHora | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body" *ngIf="ehTA || ehTVF">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Número</label>
                                            <p>{{ placa.numero }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Unidade Fiscal</label>
                                            <p>{{ placa.unidadeFiscalNome }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Data/Hora Geração</label>
                                            <p>{{ placa.dataHora | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body" *ngIf="ehRPA">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Número</label>
                                            <p>{{ placa.numero }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Nome Equipamento</label>
                                            <p>{{ placa.nomeEquipamento }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">UF Equipamento</label>
                                            <p>{{ placa.ufEquipamento }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Data/Hora Geração</label>
                                            <p>{{ placa.dataHora | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">CNPJ Operador</label>
                                            <p>{{ placa.cnpjOperador }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label">Foto</label>
                                            <p>{{ placa.foto }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm default" (click)="voltar()">Voltar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"><app-map></app-map></div>
    </div>
</my-content>